<template>
  <v-app>
    <v-main style="width: 100%; height: 100%;">
      <div id="player"></div>
    </v-main>
  </v-app>
</template>

<script>
import aws4 from 'aws4';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// readonly access
var accessKey = 'OAGBR68DL9PXDB9F3UKS';
var secretKey = 'e9BEhMD78DSP7cOQqsgwBHIBdHMEWiXDz0cMhUP0';

let firestore = firebase.firestore();
let movieRef = firestore.collection('movies');

export default {
  metaInfo () {
    if (this.translation && this.translation.title) {
      return {
        title: this.translation.title
      };
    }
    return {
      title: this.movie.title
    };
  },
  data () {
    return {
      userId: firebase.auth().currentUser.uid,
      updating: false,
      startTime: 0,
      movieId: null,
      movie: {},
      watchRef: null,
      playRef: null,
      watchingTime: 0
    };
  },
  methods: {
    readableAudioName: function (name) {
      name = name.toLowerCase();
      if (name.includes('french') || name.includes('fre') || name.includes('fr')) {
        if (name.includes('vfq')) {
          return 'French VFQ';
        }
        return 'French';
      }
      if (name.includes('english') || name.includes('eng') || name.includes('en')) {
        return 'English';
      }
      if (name.includes('spanish') || name.includes('spa') || name.includes('es')) {
        return 'Spanish';
      }
      return name;
    },
    readableSubtitleName: function (name) {
      name = name.toLowerCase();
      if (name.includes('french') || name.includes('fre') || name.includes('fr')) {
        if (name.includes('force')) {
          if (name.includes('vfq')) {
            return 'French VFQ (Forced)';
          }
          if (name.includes('colored')) {
            return 'French Colored (Forced)';
          }
          return 'French (Forced)';
        }
        if (name.includes('sdh')) {
          return 'French (Audio Description)';
        }
        if (name.includes('comment')) {
          return 'French (Commentary)';
        }
        return 'French';
      }
      if (name.includes('english') || name.includes('eng') || name.includes('en')) {
        if (name.includes('force')) {
          return 'English (Forced)';
        }
        if (name.includes('sdh')) {
          return 'English (Audio Description)';
        }
        if (name.includes('comment')) {
          return 'English (Commentary)';
        }
        return 'English';
      }
      if (name.includes('spanish') || name.includes('spa') || name.includes('es')) {
        if (name.includes('force')) {
          return 'Spanish (Forced)';
        }
        return 'Spanish';
      }
      return name;
    },
    initialize: function () {
      // initialize jwplayer
      // eslint-disable-next-line no-undef
      var playerInstance = jwplayer('player');
      // configure jwplayer instance
      playerInstance.setup({
        width: 'auto',
        height: '100%',
        autostart: 'viewable',
        stretching: 'uniform',
        cast: {
          customAppId: '03388A69'
        },
        playlist: [
          {
            file:
              'https://movies.le-navet.com/' +
              this.movieId +
              '/master.m3u8',
            onXhrOpen: function (xhr, url) {
              var parsedUrl = new URL(url);

              var opts = {
                service: 's3',
                region: 'eu-central-1',
                method: 'GET',
                host: 'movies.le-navet.com',
                path: parsedUrl.pathname
                // signQuery: true
              };

              aws4.sign(opts, {
                accessKeyId: accessKey,
                secretAccessKey: secretKey
              }); // assumes AWS credentials are available in process.env

              for (let header in opts.headers) {
                if (opts.headers.hasOwnProperty(header) && header !== 'Host') {
                  xhr.setRequestHeader(header, opts.headers[header]);
                }
              }
            },
            type: 'm3u8',
            title: this.movie.title,
            description: this.movie.overview,
            image: 'https://assets.le-navet.com/static/images' + this.movie.backdrop_path,
            starttime: this.startTime,
            tracks: [
              {
                file: 'https://europe-west1-turnip-820de.cloudfunctions.net/thumbnails?type=movie&id=' + this.movieId,
                kind: 'thumbnails'
              }
            ]
          }
        ]
      });

      playerInstance.on('ready', (e) => {
        this.playRef = firebase.firestore().collection('plays').doc();
        this.playRef.set({
          type: 'movie',
          id: this.movieId,
          timestamp: new Date(),
          time: 0,
          duration: null,
          watchingTime: 0,
          user: {
            uid: firebase.auth().currentUser.uid,
            displayName: firebase.auth().currentUser.displayName,
            email: firebase.auth().currentUser.email
          }
        });
      });

      playerInstance.on('time', (e) => {
        if (!this.updating && e.position > 5) {
          this.updating = true;
          this.watchingTime += 5;
          this.playRef.update({
            time: Math.floor(e.position),
            duration: Math.floor(e.duration),
            watchingTime: this.watchingTime
          });
          let watched = false;
          // Consider a movie watched if less than 6 minutes are remaining
          if (Math.floor(e.duration) - Math.floor(e.position) <= 360) {
            watched = true;
          }
          this.watchRef.update({
            modified: new Date(),
            time: Math.floor(e.position),
            duration: Math.floor(e.duration),
            watched: watched
          }).finally(() => {
            setTimeout(() => {
              this.updating = false;
            }, 5000);
          });
        }
      });

      playerInstance.on('audioTracks', (e) => {
        for (let i = 0; i < e.tracks.length; i++) {
          e.tracks[i].name = this.readableAudioName(e.tracks[i].name);
        }
      });
      playerInstance.on('captionsList', (e) => {
        for (let i = 0; i < e.tracks.length; i++) {
          if (e.tracks[i].id !== 'off') {
            e.tracks[i].label = this.readableSubtitleName(e.tracks[i].label);
          }
        }
      });
      /* paid only
      playerInstance.on('ready', () => {
        watchRef.get().then(watch => {
          playerInstance.seek(watch.data().time);
        })
      });
      */
    }
  },
  created: function () {
    this.movieId = this.$route.params.id;
    this.watchRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('movie_watches').doc(this.movieId);
    this.watchRef.get().then(watch => {
      if (watch.data()) {
        this.startTime = watch.data().time;
      } else {
        this.watchRef.set({
          id: parseInt(this.movieId),
          created: new Date(),
          modified: new Date(),
          watched: false,
          time: 0
        })
      }
    }).finally(() => {
      movieRef.doc(this.movieId).get().then(movie => {
        this.movie = movie.data();
        this.initialize();
      });
    });
  }
};
</script>

<style scoped>
  html, body, #player {
    background-color: transparent;
    color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  body {
    background-color: black;
  }

</style>
